import i18next from "i18next";
import XHR from "i18next-xhr-backend";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

const locales: any = {
  en: "247b0962c8453eef28120b6eb3f56bf3",
  fr: "b201d95e85333fbbc034930ff4598221"
};

const options = {
  loadPath: (lngs: string[], namespace: string[]): string => {
    return `https://api.phraseapp.com/api/v2/projects/2187057db4ce511f3bc0c238af6cf8af/locales/${
      locales[lngs[0]]
    }/download?file_format=simple_json&tags=${namespace[0]}`;
  },

  customHeaders: {
    "Cache-Control": "no-store",
    Authorization:
      "token 823bbaf5b167d37dd79cabdfe7b5a81746d4562dc9cc1ec851268c7a1d378629",
    "Content-Type": "application/json"
  }
};

declare global {
  interface InitOptionsExtended extends i18next.InitOptions {
    supportedLanguages: string[];
  }
}

i18next
  .use(LanguageDetector)
  .use(XHR)
  .use(initReactI18next)
  .init({
    load: "languageOnly",
    fallbackLng: "en",
    defaultNS: "frontend",
    ns: ["frontend"],
    backend: options,
    supportedLanguages: ["en", "fr"],
    whitelist: ["en", "fr"],
    keySeparator: ":::",
    nsSeparator: "::"
  } as InitOptionsExtended);
