import { palette } from "./Theme/palette";
import { typography } from "./Theme/typography";
import { image } from "./Theme/image";

import { AuthModule } from "../Modules/Auth";
import { StorefrontModule } from "../Modules/Storefront";
import { DashboardModule } from "../Modules/Dashboard";
import { HistoryModule } from "../Modules/History";
import { ShoppingCartModule } from "../Modules/ShoppingCart";
import { CheckoutModule } from "../Modules/Checkout";
import { InfoCenterModule } from "../Modules/InfoCenter";
import { StatisticsModule } from "../Modules/Statistics";
import { CompanyProfileModule } from "../Modules/CompanyProfile";
import { LinkModule } from "../Modules/Footer";
//import { VolumeControlModule } from "./Modules/VolumeControl";
//import { RegistrationModule } from "./Modules/Registration";

const config: any = {
  name: "unbranded",
  palette: palette,
  typography: typography,
  image: image,
};

config.modules = [
  DashboardModule,
  StatisticsModule,
  StorefrontModule,
  ShoppingCartModule,
  HistoryModule,
  CheckoutModule,
  InfoCenterModule,
  CompanyProfileModule,
  AuthModule,
  LinkModule,
];

export default config;
