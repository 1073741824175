import React from "react";
import AppContainer from "../AppContainer";
import { FlagsProvider } from "../orderingFlags";

const FeaturesContainer = () => {
  return (
    <FlagsProvider
      flags={{
        SSOButtonType: "standard",
        allowedRegister: false,
        minimumOrder: false,
        volumeControl: { enabled: false },
        allowedCountries: [{ code: "FR", name: "France" }],
        footers: {
          imprint: {
            markdown: false,
            links: {
              en: "https://www.syndicat-cotesdurhone.com/page/mentions-legales-et-credits",
              fr: "https://www.syndicat-cotesdurhone.com/page/mentions-legales-et-credits",
            },
          },
          privacypolicy: {
            markdown: true,
            links: null,
          },
          contact: {
            markdown: false,
            links: {
              en: "https://www.syndicat-cotesdurhone.com/page/contacts-au-syndicat-general",
              fr: "https://www.syndicat-cotesdurhone.com/page/contacts-au-syndicat-general",
            },
          },
        },
        companyProfile: {
          userPanel: false,
          nonEditableAddressCategories: ["BILLING"],
        },
      }}
    >
      <AppContainer />
    </FlagsProvider>
  );
};

export default FeaturesContainer;
