export const palette = {
  material: {
    type: "light",
    primary: {
      main: "#ed1c24",
    },
    secondary: {
      main: "#000000",
    },
    background: {
      paper: "#fff",
      default: "#ffffff",
    },
  },
  generic: [
    "#B21015"
  ],
  statisticsDashboard: [0],
};
