export const typography = {
  default: {
    useNextVariants: true,
    h2: {
      fontSize: 60
    },
    h3: {
      fontSize: 48,
      fontWeight: 700,
      fontFamily: "Roboto Condensed, Helvetica, Arial, sans-serif"
    },
    h4: {
      fontSize: 34,
      fontWeight: 700,
      fontFamily: "Roboto Condensed, Helvetica, Arial, sans-serif"
    },
    h6: {
      fontSize: 20,
      fontWeight: 300
    }
  },
  mobile: {
    useNextVariants: true,
    h2: {
      fontSize: 40
    },
    h3: {
      fontSize: 34,
      fontWeight: 700,
      fontFamily: "Roboto Condensed, Helvetica, Arial, sans-serif"
    },
    h4: {
      fontSize: 24,
      fontWeight: 700,
      fontFamily: "Roboto Condensed, Helvetica, Arial, sans-serif"
    },
    h6: {
      fontSize: 16,
      fontWeight: 300
    },
    body2: {
      fontSize: 10
    }
  }
};
